<template>
  <div class="home">
    <GZ/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import GZ from "@/views/CRM";

export default {
  name: 'Home',
  components: {
    GZ,
    HelloWorld
  }
}
</script>
